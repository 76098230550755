<template>
  <div class="visit-manage">
    <div ref="ActionBar" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="回访时间起：">
          <el-date-picker
            v-model="form.interviewStartTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="回访时间止：">
          <el-date-picker
            v-model="form.interviewEndTime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="订单编号：">
          <el-input
            v-model="form.orderId"
            placeholder="请输入订单编号"
            maxlength="24"
          ></el-input>
        </el-form-item>
        <el-form-item label="被访人：">
          <el-input
            v-model="form.interviewee"
            placeholder="请填写被访人"
            maxlength="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="回访人：">
          <el-input
            v-model="form.interviewer"
            placeholder="请填写回访人"
            maxlength="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="回访内容：">
          <el-select v-model="form.interviewContent" placeholder="请选择">
            <el-option value="" label="全部"> </el-option>
            <el-option value="0" label="产品使用情况"> </el-option>
            <el-option value="1" label="客诉处理"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!--  -->
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      :table-height="tableHeight"
      operation-width="220"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('查看回访')"
            type="text"
            size="small"
            sort="primary"
            @click="toDetail(scope.scopeRow)"
            >查看</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <!--  -->
  </div>
</template>

<script>
import { queryVisitRecordsPage } from "@/api/lib/api.js";
export default {
  data() {
    return {
      titleName: [
        {
          title: "回访时间",
          props: "interviewTime",
        },
        {
          title: "被访人",
          props: "interviewee",
        },
        {
          title: "回访人",
          props: "interviewer",
        },
        {
          title: "回访方式",
          props: "interviewWay",
          SpecialJudgment: (res) => {
            return res == 0 ? "电话回访" : res == 1 ? "微信回访" : res;
          },
        },
        {
          title: "回访内容",
          props: "interviewContent",
          SpecialJudgment: (res) => {
            return res == 0 ? "产品使用情况" : res == 1 ? "客诉处理" : res;
          },
        },
        {
          title: "关联订单",
          props: "orderId",
        },
      ],
      startList: [],
      endList: [],
      tableData: [],
      companyList: [],
      valueAddName: "",
      valueAdd: 0,
      form: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        interviewContent: "",
        interviewer: "",
        interviewee: "",
        interviewStartTime: null,
        interviewEndTime: null,
      },
      tableHeight: 600,
      showForm: false,
    };
  },
  mounted() {
    this.computeHeight();
    this.onSearch();
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const ActionBarHeight = this.$refs.ActionBar.clientHeight;
      this.TableHeight = `calc(100vh - ${ActionBarHeight + 280}px)`;
    },
    onSearch() {
      const data = {
        ...this.form,
        interviewContent: this.form.interviewContent
          ? Number(this.form.interviewContent)
          : "",
      };
      queryVisitRecordsPage(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.form.total = res.data.total;
          this.tableData = res.data.list;
        }
      });
    },
    toDetail(row) {
      console.log(row);
      this.$router.push({
        name: "visitManage_detail",
        query: {
          id: row.id,
        },
      });
    },
    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.onSearch();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.onSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.visit-manage {
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  .header {
    padding: 10px;
  }
  .form-area {
    margin-bottom: 10px;
  }
}
</style>